
/* eslint-disable */

import { ref, reactive, onMounted, provide } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";
import CfInputBox from "@/cloudfun/components/CfInputBox.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";

export default defineComponent({
  components: { CfInputBox, VForm: Form },
  setup() {
    const model = CloudFun.current?.model;
    const captchaUrl = ref("");
    const formData = reactive({
      email: "",
      captchaCode: ""
    });
    const pwdData = reactive({
      pwd: "",
      pwd2: ""
    });

    const startCountDown = ref(false);
    const submitText = ref<string>("傳送驗證碼");
    const countDownValue = 300;
    const countDown = ref(countDownValue);
    const sendKey = ref<string>("");
    const changeKey = ref<string>("");
    const verificationCode = ref<string>("");

    const getCaptcha = () => {
      model?.dispatch("system/captcha").then((response: any) => {
        console.log("response", response);
        if (response.status) {
          captchaUrl.value = response.payload.imageBase64;
        } else {
          CloudFun.send("error", {
            subject: "驗證碼取得失敗",
            content: response.message
          });
        }
      });
    };

    const step1ValidationSchema = Yup.object().shape({
      email: Yup.string().required("必要欄位")
    });
    const step2ValidationSchema = Yup.object().shape({
      verificationCode: Yup.string().required("必要欄位")
    });
    const step3ValidationSchema = Yup.object().shape({
      password: Yup.string()
        .min(8, "不得小於8位元")
        .max(20, "不得大於20位元")
        .required("必要欄位"),
      password2: Yup.string()
        .min(8, "不得小於8位元")
        .max(20, "不得大於20位元")
        .oneOf([Yup.ref("password")], "請輸入一致的密碼")
        .required("必要欄位")
    });

    onMounted(() => {
      getCaptcha();
    });

    const reset = () => {
      cash("#passwordSteper1").addClass("active");
      cash("#passwordStep1").addClass("active");
      cash("#passwordSteper2").removeClass("active");
      cash("#passwordStep2").removeClass("active");
      cash("#passwordSteper3").removeClass("active");
      cash("#passwordStep3").removeClass("active");
      cash("#passwordSteper4").removeClass("active");
      cash("#passwordStep4").removeClass("active");
      formData.email = "";
      submitText.value = "傳送驗證碼";
    };

    const doCountDown = async function() {
      startCountDown.value = true;
      countDown.value = countDownValue;
      const timer = setInterval(() => {
        if (countDown.value > 0 && startCountDown.value) {
          countDown.value -= 1;
          console.log("countDown", countDown.value);
          if (countDown.value == 0) startCountDown.value = false;
        }
      }, 1000);
      if (!startCountDown.value) {
        countDown.value = countDownValue;
        clearInterval(timer);
      }
    };

    return {
      captchaUrl,
      getCaptcha,
      formData,
      step1ValidationSchema,
      step2ValidationSchema,
      step3ValidationSchema,
      sendKey,
      reset,
      submitText,
      countDown,
      doCountDown,
      verificationCode,
      startCountDown,
      changeKey,
      pwdData
    };
  },
  methods: {
    toPasswordStep2() {
      cash("#passwordSteper1").removeClass("active");
      cash("#passwordStep1").removeClass("active");
      cash("#passwordSteper2").addClass("active");
      cash("#passwordStep2").addClass("active");
    },
    toPasswordStep3() {
      cash("#passwordSteper2").removeClass("active");
      cash("#passwordStep2").removeClass("active");
      cash("#passwordSteper3").addClass("active");
      cash("#passwordStep3").addClass("active");
    },
    toPasswordStep4() {
      cash("#passwordSteper3").removeClass("active");
      cash("#passwordStep3").removeClass("active");
      cash("#passwordSteper4").addClass("active");
      cash("#passwordStep4").addClass("active");
    },
    reloadCaptcha() {
      this.getCaptcha();
    },
    async submit(value: any) {
      const sendKey = await this.getSendKey(value);
      //console.log('sendKey', sendKey)
      if (sendKey) {
        this.sendKey = sendKey;
        this.toPasswordStep2();
        //const code = await this.getCode(sendKey);
        //console.log(code)
      }
    },
    async changePwd() {
      await this.postChange();
    },
    async postCode() {
      if (this.verificationCode) {
        const changeKey = await this.codeVerify();
        if (changeKey) {
          this.changeKey = changeKey;
          this.toPasswordStep3();
        }
      } else {
        CloudFun.send("error", {
          subject: "忘記密碼",
          content: "請輸入驗證碼"
        });
      }
    },
    async postChange() {
      if (this.pwdData.pwd != this.pwdData.pwd2) {
        CloudFun.send("error", {
          subject: "忘記密碼",
          content: "請輸入一致的密碼"
        });
        return;
      }
      try {
        return this.$model
          .dispatch("system/forgotPasswordChange", {
            changeKey: this.changeKey,
            pwd: this.pwdData.pwd
          })
          .then((response: any) => {
            //console.log("forgot response", response);
            if (response.status) {
              this.toPasswordStep4();
            } else {
              CloudFun.send("error", {
                subject: "忘記密碼",
                content: response.message ?? "系統忙碌中，請稍後再試"
              });
            }
          });
      } catch (error) {
        console.log("error", error);
        CloudFun.send("error", {
          subject: "忘記密碼",
          content: "系統忙碌中，請稍後再試"
        });
      }
    },
    codeVerify() {
      try {
        return this.$model
          .dispatch("system/forgotPasswordVerify", {
            code: this.verificationCode
          })
          .then((response: any) => {
            //console.log("forgot response", response);
            if (response.status) {
              return response.payload.changeKey;
            } else {
              CloudFun.send("error", {
                subject: "忘記密碼",
                content: response.message ?? "系統忙碌中，請稍後再試"
              });
            }
          });
      } catch (error) {
        console.log("error", error);
        CloudFun.send("error", {
          subject: "忘記密碼",
          content: "系統忙碌中，請稍後再試"
        });
      }
    },
    getSendKey(value: any) {
      try {
        return this.$model
          .dispatch("system/forgotPassword", { account: value.email })
          .then((response: any) => {
            //console.log("forgot response", response);
            if (response.status && !response.payload?.isUnVerified) {
              return response.payload.sendKey;
            } else {
              CloudFun.send("error", {
                subject: "忘記密碼",
                content: response.message ?? "系統忙碌中，請稍後再試"
              });
            }
          });
      } catch (error) {
        console.log("error", error);
        CloudFun.send("error", {
          subject: "忘記密碼",
          content: "系統忙碌中，請稍後再試"
        });
      }
    },
    getCode() {
      try {
        return this.$model
          .dispatch("system/forgotPasswordSend", { sendKey: this.sendKey })
          .then((response: any) => {
            //console.log("forgot response", response);
            if (response.status) {
              CloudFun.send("info", {
                subject: "忘記密碼",
                content: "成功發送驗證信"
              });
              this.submitText = "重新傳送驗證碼";
              this.doCountDown();
              return response.payload.code;
            } else {
              CloudFun.send("error", {
                subject: "忘記密碼",
                content: "系統忙碌中，請稍後再試"
              });
            }
          });
      } catch (error) {
        console.log("error", error);
        CloudFun.send("error", {
          subject: "忘記密碼",
          content: "系統忙碌中，請稍後再試"
        });
      }
    }
  }
});
