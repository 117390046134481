<template>
  <div
    id="signIn-modal"
    ref="modal"
    class="modal flex justify-center items-center pointer-events-none"
  >
    <div
      class="modal__content modal__content--xl max-h-screen overflow-y-scroll sm:overflow-y-visible relative w-screen p-6 max-w-screen-lg pointer-events-auto"
    >
      <!-- <a
        data-dismiss="modal"
        href="javascript:;"
        @click="registerModal.reset"
        class="absolute right-0 top-0 mt-6 mr-6"
      >
        <xIcon class="w-8 h-8 text-gray-500" />
      </a> -->

      <div
        class="signin-stepper nav-tabs lg:flex items-center justify-between  mr-16 pb-5"
      >
        <a id="stepTo1" class="mb-2 active">
          <span class="">01</span>選擇註冊帳號類型
        </a>
        <div class=" w-12 h-0.5 bg-gray-500 hidden lg:block"></div>
        <a id="stepTo2" class="mb-2 "> <span class="">02</span>設定帳號資料 </a>
        <div class=" w-12 h-0.5 bg-gray-500 hidden lg:block"></div>
        <a id="stepTo3" class="mb-2 ">
          <span class="">03</span>信箱or簡訊驗證
        </a>
        <div class=" w-12 h-0.5 bg-gray-500 hidden lg:block"></div>
        <a id="stepTo4" class="mb-2 "> <span class="">04</span>完成註冊！ </a>
      </div>

      <div class="tab-content">
        <!-- 註冊中斷或連線逾時畫面 -->
        <div id="" class="tab-content__pane">
          <div class="font-bold text-2xl xl:text-3xl mt-3">Oops!</div>
          <div class="pb-12 pt-2 px-0">
            <div class="text-xl mb-8">註冊中斷或操作逾時</div>
            <!-- <div class="text-xl mb-8">您的帳號註冊未完成，請先完成email驗證</div> -->
            <!-- <div class="text-xl mb-8">您的帳號註冊未完成，請先完成簡訊驗證</div> -->
            <div class=" text-base">
              請重新登入並完成驗證
            </div>
            <div class=" sm:flex items-center my-5">
              <a
                href="#"
                class="button button--lg w-full lg:w-32 text-white bg-theme-1 align-top mb-2 lg:mb-0"
              >
                登入
              </a>
              <!-- <a href="#" class="button button--lg w-full lg:w-32 text-white bg-theme-1 align-top mb-2 lg:mb-0">
                重新發送驗證碼
              </a> -->
            </div>
          </div>
        </div>

        <!-- 選擇您要註冊的帳號類型 -->
        <div id="step01" class="tab-content__pane active">
          <a
            data-dismiss="modal"
            href="javascript:;"
            ref="closeModal"
            @click="registerModal.reset"
            class="absolute right-0 top-0 mt-6 mr-6"
          >
            <xIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="font-bold text-2xl xl:text-3xl mt-3 mb-10">
            選擇您要註冊的帳號類型
          </div>
          <div class="py-5 lg:grid lg:grid-cols-2 gap-2 mb-7 px-0">
            <div class=" text-center mb-6 lg:mb-3">
              <div class=" text-2xl font-semibold mb-2">一般帳號</div>
              <div class=" text-gray-600 ">快速掌握平台消息</div>
              <div class="mx-auto w-72 h-20 text-justify text-gray-600 my-7">
                透過兩步驟簡單註冊成為一般帳號，快速接收平台活動與展覽內容，最新消息不漏接。
              </div>
              <div class="text-center">
                <button
                  @click="toStep2(false)"
                  type="button"
                  class="button  button--lg w-full lg:w-32 text-white bg-theme-1 align-top mb-2 xl:mb-0"
                >
                  開始註冊
                </button>
              </div>
            </div>

            <div class=" text-center mb-3">
              <div class=" text-2xl font-semibold mb-2">買家帳號</div>
              <div class=" text-gray-600 ">完成實名制，直接與賣家互動</div>
              <div class="mx-auto w-72 h-20 text-justify text-gray-600 my-7">
                完整享受平台服務，透過方便的即時商洽系統，針對您感興趣的產品快速進行詢問。
              </div>
              <div class="text-center">
                <button
                  @click="toStep2(true)"
                  type="button"
                  class="button  button--lg w-full lg:w-32 text-white bg-theme-1 align-top mb-2 xl:mb-0"
                >
                  開始註冊
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- 設定帳號資料 -->
        <div id="step02" class="tab-content__pane">
          <a
            data-dismiss="modal"
            href="javascript:;"
            @click="registerModal.reset"
            class="absolute right-0 top-0 mt-6 mr-6"
          >
            <xIcon class="w-8 h-8 text-gray-500" />
          </a>
          <v-form :validation-schema="schema.step02" @submit="toStep2info">
            <div class="font-bold text-2xl xl:text-3xl my-3">設定帳號資料</div>
            <div class="py-5 lg:grid lg:grid-cols-2 gap-4 px-0">
              <div class="mb-5 lg:mb-0 col-span-1">
                <CfInputBox
                  label="帳號"
                  name="account"
                  placeholder="Email／行動電話"
                  type="text"
                  :isRequired="true"
                  v-model="registerModal.formData.account"
                ></CfInputBox>
                <!-- <div>
                <label class=" font-medium text-lg"
                  >帳號<span class=" text-red-400">＊</span></label
                >
                <input
                  type="text"
                  v-model="registerModal.formData.account"
                  class="intro-x input input--lg border border-gray-300 w-full mt-2 mb-3"
                  placeholder="Email／行動電話"
                />
              </div> -->
              </div>
            </div>
            <div class="lg:grid lg:grid-cols-2 gap-4 px-0">
              <div class="">
                <div>
                  <label class=" font-medium text-lg"
                    >密碼<span class=" text-red-400">＊</span></label
                  >
                  <CfInputBox
                    name="password"
                    placeholder="設定密碼"
                    type="password"
                    v-model="registerModal.formData.pwd"
                  ></CfInputBox>

                  <!-- <input
                      type="password"
                      v-model="registerModal.formData.pwd"
                      class="intro-x input input--lg border border-gray-300 w-full mt-2 mb-3"
                      placeholder="設定密碼"
                    /> -->
                </div>
                <div>
                  <CfInputBox
                    name="passwordConfirm"
                    placeholder="再次輸入密碼"
                    type="password"
                    v-model="registerModal.formData.pwd2"
                  ></CfInputBox>
                  <!-- <input
                      type="password"
                      v-model="registerModal.formData.pwd2"
                      class="intro-x input input--lg border border-gray-300 w-full mt-2 mb-3"
                      placeholder="再次輸入密碼"
                    /> -->
                </div>
              </div>
              <div class=" lg:mt-8 mb-8 text-gray-600">
                <div class="">* 6-24字元</div>
                <div class="">
                  * 須包含至少一個大寫英文字母與一個小寫英文字母
                </div>
                <div class="">* 不可使用標點符號、特殊符號</div>
              </div>
            </div>
            <div
              class=" lg:flex items-center justify-between pb-5 border-b border-theme-5"
            >
              <div class=" ml-1">
                <div class=" my-1 flex items-center">
                  <input
                    class=" mr-2"
                    type="checkbox"
                    name="agreeTerms"
                    id="agreeTerms"
                    v-model="check1"
                    @change="checkChange"
                  />
                  <label for="agreeTerms">
                    我已詳細閱讀並同意
                  </label>
                  <a
                    data-toggle="modal"
                    data-target="#terms-modal"
                    class=" text-theme-10"
                  >
                    個人資料搜集與利用聲明
                  </a>
                </div>
                <div class=" my-1 flex items-center">
                  <input
                    class=" mr-2"
                    type="checkbox"
                    name="agreeCookie"
                    id="agreeCookie"
                    v-model="check2"
                    @change="checkChange"
                  />
                  <label for="agreeCookie">
                    我同意允許網站使用Cookie
                  </label>
                </div>
              </div>
              <div class="flex items-center justify-end">
                <button
                  @click="toStep1()"
                  type="button"
                  class="button button--lg w-full lg:w-32 text-theme-1 bg-transparent lg:mr-3 align-top"
                >
                  上一步
                </button>
                <button
                  type="submit"
                  class="button  button--lg w-full lg:w-32 text-white bg-theme-1  align-top mb-2 lg:mb-0"
                  :class="{ 'bg-theme-25': !check1 || !check2 }"
                  :disabled="!check1 || !check2"
                >
                  下一步
                </button>
                <!-- <button
                  @click="toStep2info()"
                  type="button"
                  class="button  button--lg w-full lg:w-32 text-white bg-theme-1 align-top mb-2 lg:mb-0"
                >
                  下一步
                </button> -->
              </div>
            </div>
            <div class="mt-5" v-show="!registerModal.formData.isBuyer">
              <div class=" text-left text-gray-600 mb-3">
                或使用社群帳號快速註冊
              </div>
              <div
                class=" lg:flex items-center intro-x text-center lg:text-left mb-5"
              >
                <!-- <a
                  href="#"
                  class="button button--lg w-full lg:w-32 text-white bg-theme-1 lg:mr-3 align-top mb-2 lg:mb-0 block"
                  :class="{ 'bg-theme-25': false }"
                  @click="facebookLogin"
                >
                  FEACEBOOK
                </a> -->
                <!-- <a
                  href="#"
                  class="button button--lg w-full lg:w-32 text-white bg-theme-1 lg:mr-3 align-top mb-2 lg:mb-0 block"
                  :class="{ 'bg-theme-25': true }"
                  :disabled="true"
                >
                  LINE
                </a> -->
                <a
                  href="#"
                  class="button button--lg w-full lg:w-32 text-white bg-theme-1 lg:mr-3 align-top mb-2 lg:mb-0 block"
                  :class="{ 'bg-theme-25': false }"
                  @click="googleLogin"
                >
                  GOOGLE
                </a>
              </div>
            </div></v-form
          >
        </div>

        <!-- 填寫個人資料 -->
        <div id="step02info" class="tab-content__pane">
          <v-form
            :validation-schema="
              accountType == 'email'
                ? schema.step02InfoForMobile
                : schema.step02InfoForEmail
            "
            @submit="toStep3"
          >
            <div class="font-bold text-2xl xl:text-3xl my-3">
              填寫買家實名資料
            </div>
            <div class=" text-gray-600">
              為保障您的權益,請確實填寫下列資料。
            </div>
            <div class="py-5 lg:grid lg:grid-cols-2 gap-4 px-0">
              <div class="mb-5 lg:mb-0">
                <div>
                  <CfInputBox
                    label="真實姓名"
                    name="name"
                    placeholder="真實姓名"
                    type="text"
                    :isRequired="true"
                    v-model="registerModal.formData.name"
                  ></CfInputBox>
                  <!-- <label class=" font-medium text-lg"
                    >真實姓名<span class=" text-red-400">＊</span></label
                  >
                  <input
                    type="text"
                    v-model="registerModal.formData.name"
                    class="intro-x input input--lg border border-gray-300 w-full mt-2 mb-3"
                    placeholder="真實姓名"
                  /> -->
                </div>
                <div>
                  <CfInputBox
                    v-if="accountType === 'email'"
                    label="聯絡電話"
                    name="mobile"
                    placeholder="聯絡電話"
                    type="text"
                    :isRequired="true"
                    v-model="registerModal.formData.mobile"
                  ></CfInputBox>

                  <CfInputBox
                    v-if="accountType !== 'email'"
                    label="E-mail"
                    name="email"
                    placeholder="E-mail"
                    type="text"
                    :isRequired="true"
                    v-model="registerModal.formData.email"
                  ></CfInputBox>
                  <!-- <label class=" font-medium text-lg"
                    >聯絡電話<span class=" text-red-400">＊</span></label
                  >
                  <input
                    type="text"
                    v-model="registerModal.formData.mobile"
                    class="intro-x input input--lg border border-gray-300 w-full mt-2 mb-3"
                    placeholder="聯絡電話"
                  /> -->
                </div>
              </div>
              <div class="">
                <div>
                  <CfSelectBox
                    label="性別"
                    name="sex"
                    placeholder="性別"
                    :isRequired="true"
                    :items="gender"
                    v-model="registerModal.formData.sex"
                  ></CfSelectBox>

                  <!-- <label class=" font-medium text-lg"
                    >性別<span class=" text-red-400">＊</span></label
                  >
                  <div class="mt-2">
                    <select
                      v-model="registerModal.formData.sex"
                      class="intro-x input input--lg border border-gray-300 w-full"
                    >
                      <option
                        v-for="item in gender"
                        :key="item.value"
                        :value="item.value"
                        >{{ item.text }}</option
                      >
                    </select> 
                  </div>-->
                </div>
              </div>
            </div>
            <div class=" flex items-center justify-end pb-5">
              <button
                @click="toStep2(registerModal.formData.isBuyer)"
                type="button"
                class="button button--lg w-full lg:w-32 text-theme-1 bg-transparent lg:mr-3 align-top"
              >
                上一步
              </button>
              <button
                type="submit"
                class="button  button--lg w-full lg:w-32 text-white bg-theme-1 align-top mb-2 lg:mb-0"
              >
                下一步
              </button>
            </div>
          </v-form>
        </div>

        <!-- 確認您的手機簡訊orEmail地址 -->
        <div id="step03" class="tab-content__pane">
          <v-form
            :validation-schema="schema.step03"
            @submit="registerModal.sendVerify"
          >
            <div class="">
              <div class="font-bold text-2xl xl:text-3xl my-3">
                確認您的{{ accountType === "email" ? "E-Mail" : "手機簡訊" }}
              </div>
              <!-- <div class="font-bold text-2xl xl:text-3xl my-3">確認您的email地址</div> -->
              <div class="py-12 px-0">
                <div class=" text-base">
                  我們剛將驗證碼傳送至 {{ registerModal.formData.account
                  }}<br />請於{{ countDownValue }}秒內輸入{{
                    accountType === "email" ? "E-Mail" : "簡訊"
                  }}中的驗證碼，完成帳號啟用。
                </div>
                <!-- <div class=" text-base">
                我們已經送出驗證信至 OOOO@gmail.com<br>請於300秒內輸入簡訊中的驗證碼，完成帳號啟用。
              </div> -->
                <div class=" sm:flex items-center my-5">
                  <div class=" flex items-center">
                    <CfInputBox
                      name="verificationCode"
                      placeholder="驗證碼"
                      type="text"
                      :showMessage="false"
                      v-model="verificationCode"
                      @update:modelValue="
                        val => {
                          verificationCode = val;
                        }
                      "
                    ></CfInputBox>
                    <!-- <input
                      type="text"
                      class="intro-x input input--lg border border-gray-300 block mr-3"
                      placeholder="簡訊驗證碼"
                    /> -->
                    <button
                      type="submit"
                      class="button  button--lg w-full lg:w-32 text-white bg-theme-1 align-top mb-2 lg:mb-0"
                    >
                      送出
                    </button>
                  </div>
                  <div class="">
                    <div class="flex items-center">
                      <a
                        href="#"
                        class=" button text-theme-1 bg-transparent mb-3 sm:mb-0 align-top"
                        v-if="startCountDown"
                      >
                        {{ countDown }} 秒
                      </a>

                      <a
                        href="#"
                        @click="registerModal.getVerify"
                        :class="{ 'a-disabled': startCountDown }"
                        class=" button text-theme-1 bg-transparent mb-3 sm:mb-0 align-top"
                        v-if="!startCountDown"
                      >
                        {{ submitText }}
                      </a>
                    </div>

                    <!-- <button
                      @click="
                        () => {
                          verifySms = true;
                        }
                      "
                      class="btn-link text-theme-10 pl-3"
                      v-if="
                        accountType == 'phone' && resendBody?.key?.length > 0
                      "
                    >
                      收不到驗證簡訊？
                    </button> -->

                    <a
                      data-toggle="modal"
                      data-target="#no-verify-sms-modal"
                      class=" btn-link text-theme-10 pl-3"
                      v-if="
                        accountType == 'phone' && resendBody?.key?.length > 0
                      "
                    >
                      始終沒有收到驗證信？
                    </a>

                    <button
                      @click="toChange(registerModal.formData.isBuyer)"
                      class="btn-link text-theme-10 pl-3"
                      v-if="
                        accountType == 'email' && resendBody?.key?.length > 0
                      "
                    >
                      始終沒有收到驗證信？換一個帳號試試
                    </button>

                    <!-- <a href="#" class="button button--lg text-theme-1 bg-transparent mb-3 sm:mb-0 align-top">
                    重新發送驗證碼
                  </a> -->
                    <!-- <a
                    href="#"
                    class="button button--lg text-theme-7 bg-transparent mb-3 sm:mb-0 align-top"
                    v-if="!startCountDown"
                  >
                    收不到驗證簡訊？
                  </a> -->
                    <!-- <a href="#" class="button button--lg text-theme-7 bg-transparent mb-3 sm:mb-0 align-top">
                    始終沒有收到驗證信 ?
                  </a> -->
                  </div>
                </div>
                <div class=" flex justify-end">
                  <div class=" text-center">
                    <button
                      @click="toChange(registerModal.formData.isBuyer)"
                      class="button button--lg w-full lg:w-32 text-theme-1 bg-transparent border border-solid border-theme-1"
                    >
                      上一步
                    </button>
                    <div class=" text-gray-500 text-sm">換一個帳號試試</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 收不到驗證簡訊?-->
            <div v-if="verifySms == true">
              <div class="font-bold text-2xl mb-3 -mt-8">
                收不到驗證簡訊？
              </div>
              <div class="py-5">
                <ol class=" list-decimal pl-5">
                  <li class="mb-2">
                    訊號不佳或者網路壅塞皆會導致無法收取驗證碼，建議可至訊號通順良好的地方再次獲取驗證碼，或者稍後重新獲取即可。
                  </li>
                  <li class="mb-2">
                    部分電信業者有提供的"拒收企業簡訊"功能，該功能可能導致無法正常接受驗證碼簡訊，若需查詢、關閉此功能請自行洽詢所屬電信商。
                  </li>
                  <li class="mb-2">
                    確認是否設定來電攔截APP，如果有設定到黑名單，由於驗證簡訊通常為系統大量發送訊息，很可能被手機判為廣告，請解除攔截或封鎖，避免簡訊接收失敗。
                  </li>
                  <li class="mb-2">
                    確認手機是否開啟「勿擾模式」或「飛航模式」，導致簡訊接收失敗。
                  </li>
                  <li class="mb-2">
                    若您使用iOS手機，訊息裡的設定請不要使用 3rd party
                    程式來封鎖/過濾黑名單聯絡人，避免簡訊接收失敗。
                  </li>
                  <li class="mb-2">
                    確認手機內存空間，若內存空間已滿將無法接收簡訊驗證碼。
                  </li>
                  <li class="mb-2">
                    除了上述情況外，若仍無法收受簡訊驗證碼，建議您可依以下方式進行手機操作排除以下狀況:
                  </li>
                  <li class="mb-2">
                    (1)更新收受簡訊的APP至最新版本 <br />
                    (2)清除簡訊APP的內存(清除緩存) <br />
                    (3)開啟並關閉飛航模式，重啟網路環境 <br />
                    (4)將手機重啟
                  </li>
                </ol>
              </div>
              <!-- <div class=" flex items-center justify-end pb-5">
                <button
                  type="button"
                  class="button button--lg w-full lg:w-32 text-white bg-theme-1 align-top mb-2 lg:mb-0"
                >
                  下一步
                </button>
              </div> -->
            </div>
            <!-- 始終沒有收到驗證信?-->
            <div class=" hidden">
              <div class="font-bold text-2xl xl:text-3xl my-3">
                始終沒有收到驗證信？
              </div>
              <div class="py-5">
                <div class="flex flex-col md:flex-row items-center mb-4">
                  <label class="w-full md:w-28 md:mr-3">換一個信箱試試</label>
                  <input
                    type="email"
                    class=" w-full md:w-72 input input--lg border border-gray-300 block"
                    placeholder="email"
                  />
                </div>
              </div>
              <div class=" flex items-center justify-between pb-5">
                <div class=" text-base">
                  或<a class="text-theme-1" href="#">使用行動電話註冊</a>
                </div>
                <button
                  type="button"
                  class="button button--lg w-full lg:w-32 text-white bg-theme-1 align-top mb-2 lg:mb-0"
                >
                  下一步
                </button>
              </div>
            </div></v-form
          >
        </div>

        <!-- 註冊完成 -->
        <div id="step04" class="tab-content__pane">
          <div class="font-bold text-2xl xl:text-3xl my-3">註冊完成</div>
          <div class="py-12 px-0">
            <div class=" text-base">
              歡迎您！
            </div>
            <div class=" flex items-center my-5">
              <a
                href="#"
                class="button button--lg w-full lg:w-40 text-white bg-theme-1 align-top mb-2 lg:mb-0"
                @click="() => closeModal.click()"
              >
                前往首頁
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class=" hidden sm:block absolute left-0 -bottom-10 text-white">
        Copyright © 2021.CISA中華軟體協會
      </div> -->
    </div>
  </div>
  <!-- 個人資料蒐集與利用聲明modal -->
  <template>
    <div id="terms-modal" class="modal flex justify-center items-center ">
      <div
        class="modal__content modal__content--lg max-h-screen overflow-y-scroll sm:overflow-y-visible relative w-screen p-6 max-w-screen-lg"
      >
        <a
          data-dismiss="modal"
          href="javascript:;"
          class="absolute right-0 top-0 mt-6 mr-6"
        >
          <xIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class=" text-lg font-medium mb-6">
          個人資料蒐集與利用聲明
        </div>
        <div class="py-3">
          本會基於建立會員資料庫管理及維護會員資料與促進會員利益之目的，將蒐集、處理或利用會員所提供之個人資料，
          本會謹依中華民國「個人資料保護法」之規定，告知您相關之資訊如下：<br />
          <br />
          一、個人資料蒐集目的<br />
          本會所蒐集、處理或利用之會員個人資料，其目的在於提供會員本會及產業相關資訊、邀請參加本會舉辦之會議、活動、課程、推廣或產業資訊蒐集等。<br /><br />
          二、蒐集之個人資料類別<br />
          (一)供應商會員：該團體所屬職員等所有人員之個人姓名、職稱、電話、電子郵件地址。<br />
          (二)個人會員：姓名、電話、性別、電子郵件地址。<br />
          <br />
          三、個人資料利用之期間、地區、對象及方式本會蒐集被蒐集者之個人資料，於本聲明第一條所載之目的利用之，除另外告知外皆在中華民國境內利用。本會得因主管機關之規定或蒐集目的消失時，依個人資料保護法第11
          條進行個資的刪除及停止處理利用，將不另行通知提供個資當事人。
          <br /><br />
          四、依個人資料保護法第三條之規定會員可查詢個人相關資料，僅限於本人或依法授權的第三人向本會提出查詢，查詢方式如以書面為之者，地址為103台北市大同區承德路二段239號6樓；如以電子郵件查詢，本會專屬查詢信箱地址為privacy@mail.cisanet.org.tw。
        </div>
        <div class=" text-lg font-medium mt-4 mb-3">
          個人資料之同意提供：
        </div>
        <div class="py-3">
          一、本人知悉上開告知內容<br />
          二、本人同意中華民國資訊軟體協會根據以上「個人資料蒐集與利用聲明」蒐集、處理或利用本人之個人資料。
        </div>
      </div>
    </div>
  </template>

  <template>
    <div
      id="no-verify-sms-modal"
      class="modal flex justify-center items-center "
    >
      <div
        class="modal__content max-h-screen overflow-y-scroll sm:overflow-y-visible relative w-screen p-6 max-w-screen-lg"
      >
        <a
          data-dismiss="modal"
          href="javascript:;"
          class="absolute right-0 top-0 mt-6 mr-6"
        >
          <xIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="">
          <div class="font-bold text-2xl xl:text-3xl my-3">
            收不到驗證簡訊？
          </div>
          <div class="py-5">
            <ol class=" list-decimal pl-5">
              <li class="mb-2">
                訊號不佳或者網路壅塞皆會導致無法收取驗證碼，建議可至訊號通順良好的地方再次獲取驗證碼，或者稍後重新獲取即可。
              </li>
              <li class="mb-2">
                部分電信業者有提供的"拒收企業簡訊"功能，該功能可能導致無法正常接受驗證碼簡訊，若需查詢、關閉此功能請自行洽詢所屬電信商。
              </li>
              <li class="mb-2">
                確認是否設定來電攔截APP，如果有設定到黑名單，由於驗證簡訊通常為系統大量發送訊息，很可能被手機判為廣告，請解除攔截或封鎖，避免簡訊接收失敗。
              </li>
              <li class="mb-2">
                確認手機是否開啟「勿擾模式」或「飛航模式」，導致簡訊接收失敗。
              </li>
              <li class="mb-2">
                若您使用iOS手機，訊息裡的設定請不要使用 3rd party
                程式來封鎖/過濾黑名單聯絡人，避免簡訊接收失敗。
              </li>
              <li class="mb-2">
                除了上述情況外，若仍無法收受簡訊驗證碼，建議您可依以下方式進行手機操作排除以下狀況:
              </li>
              <li class="mb-2">
                確認手機內存空間，若內存空間已滿將無法接收簡訊驗證碼<br />
                (1)更新收受簡訊的APP至最新版本 <br />
                (2)清除簡訊APP的內存(清除緩存) <br />
                (3)開啟並關閉飛航模式，重啟網路環境 <br />
                (4)將手機重啟
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </template>

  <template>
    <div
      id="no-verify-email-modal"
      class="modal flex justify-center items-center "
    >
      <div
        class="modal__content max-h-screen overflow-y-scroll sm:overflow-y-visible relative w-screen p-6 max-w-screen-lg"
      >
        <a
          data-dismiss="modal"
          href="javascript:;"
          class="absolute right-0 top-0 mt-6 mr-6"
        >
          <xIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="">
          <div class="font-bold text-2xl xl:text-3xl my-3">
            收不到驗證簡訊？
          </div>
          <div class="py-5">
            <ol class=" list-decimal pl-5">
              <li class="mb-2">
                訊號不佳或者網路壅塞皆會導致無法收取驗證碼，建議可至訊號通順良好的地方再次獲取驗證碼，或者稍後重新獲取即可。
              </li>
              <li class="mb-2">
                部分電信業者有提供的"拒收企業簡訊"功能，該功能可能導致無法正常接受驗證碼簡訊，若需查詢、關閉此功能請自行洽詢所屬電信商。
              </li>
              <li class="mb-2">
                確認是否設定來電攔截APP，如果有設定到黑名單，由於驗證簡訊通常為系統大量發送訊息，很可能被手機判為廣告，請解除攔截或封鎖，避免簡訊接收失敗。
              </li>
              <li class="mb-2">
                確認手機是否開啟「勿擾模式」或「飛航模式」，導致簡訊接收失敗。
              </li>
              <li class="mb-2">
                若您使用iOS手機，訊息裡的設定請不要使用 3rd party
                程式來封鎖/過濾黑名單聯絡人，避免簡訊接收失敗。
              </li>
              <li class="mb-2">
                除了上述情況外，若仍無法收受簡訊驗證碼，建議您可依以下方式進行手機操作排除以下狀況:
              </li>
              <li class="mb-2">
                確認手機內存空間，若內存空間已滿將無法接收簡訊驗證碼<br />
                (1)更新收受簡訊的APP至最新版本 <br />
                (2)清除簡訊APP的內存(清除緩存) <br />
                (3)開啟並關閉飛航模式，重啟網路環境 <br />
                (4)將手機重啟
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script lang="ts">
/* eslint-disable */
import axios from "axios";
import { ref, reactive, onMounted, provide, computed, watch, toRef } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";
import formatDate from "xe-utils/toDateString";
import * as Facebook from "@/components/facebook-login";
import { installGoogleAuth } from "@/components/google-login";
import { useGapi } from "vue-gapi";
import { email, required } from "@vuelidate/validators";
import CfInputBox from "@/cloudfun/components/CfInputBox.vue";
import CfSelectBox from "@/cloudfun/components/CfSelectBox.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import firebaseConfig from "@/views/firebaseConfig";
import { useRoute, useRouter } from "vue-router";
import {
  getAuth,
  signInWithPopup,
  signOut,
  GoogleAuthProvider
} from "firebase/auth";
import { boolean } from "yup/lib/locale";
export default defineComponent({
  components: { CfInputBox, VForm: Form, CfSelectBox },
  props: {
    ssoCallback: {
      type: Boolean,
      required: false
    }
  },
  setup(props, { emit }) {
    firebaseConfig;
    const model = CloudFun.current?.model;
    const router = useRouter();
    const route = useRoute();
    const submitText = ref<string>("傳送驗證碼");
    const verificationCode = ref<string>("");
    const countDownValue = 300;
    const countDown = ref(countDownValue);
    const startCountDown = ref(false);
    const resendBody = reactive({ key: "", type: "" });
    const isVisible = ref(false);
    const check1 = ref(false);
    const check2 = ref(false);
    const googleProvider = new GoogleAuthProvider();
    const auth = getAuth();
    const verifySms = ref(false);
    const ssoCllback = toRef(props, "ssoCallback");
    const closeModal = ref();

    const accountType = computed(() =>
      registerModal.formData.account?.indexOf("@") != -1 ? "email" : "phone"
    );

    const timer = ref(0);

    const registerModal = reactive({
      formData: <any>{
        isBuyer: false,
        debug_SkipVerification: true,
        account: ""
        // pwd: '',
        // pwd2: '',
        // accountType: '',
        // isBuyer: false,
        // name: ''
        // sex: ''
        // email: ''
        // mobile: ''
      },
      async getVerify() {
        // if (resendBody?.key?.length > 0) {
        //   await registerModal.getCode(resendBody.key, true);
        // } else {
        //   const code = await registerModal.getCode(resendBody.key, false);
        //   console.log(accountType.value)
        //   if(code) {
        //     submitText.value = "重新傳送驗證碼";
        //     doCountDown();
        //   }
        // }
        const code = await registerModal.getCode(resendBody.key, true);
        if (code) {
          submitText.value = "重新傳送驗證碼";
          // if (accountType.value == "phone") {
          //   //手機暫時直接通過
          //   verificationCode.value = code;
          //   await registerModal.sendVerify();
          // }
        }
      },
      async getCode(sendKey: string, countDown: boolean) {
        return await model
          ?.dispatch("system/send", {
            sendKey: sendKey
          })
          .then(response => {
            //console.log(response);
            if (response.status) {
              if (countDown) doCountDown();
              return response.payload.code;
            } else {
              CloudFun.send(response.status ? "info" : "error", {
                subject: response.status ? "發送成功" : "發送失敗",
                content: response.status ? "發送失敗" : response.message
              });
            }
          })
          .catch(error => {
            console.log(error.message);
            CloudFun.send("error", {
              subject: "驗證碼發送失敗",
              content: error.message
            });
          });
      },
      async sendVerify() {
        if (!verificationCode.value) {
          CloudFun.send("error", {
            subject: "驗證失敗",
            content: "請輸入驗證碼"
          });
          return;
        }

        await model
          ?.dispatch("system/verify", {
            code: verificationCode.value
          })
          .then(response => {
            console.log(response);
            if (response.status) {
              //toStep4
              cash("#step03").removeClass("active");
              cash("#stepTo3").removeClass("active");
              cash("#stepTo4").addClass("active");
              cash("#step04").addClass("active");

              // setTimeout(() => closeModal.value.click(), 2000);
            } else {
              CloudFun.send(response.status ? "info" : "error", {
                subject: response.status ? "註冊成功" : "註冊失敗",
                content: response.status ? "註冊成功" : response.message
              });
            }
          })
          .catch(error => {
            console.log(error.message);
            CloudFun.send("error", {
              subject: "驗證失敗",
              content: error.message
            });
          });
      },

      reset() {
        cash("#stepTo1").addClass("active");
        cash("#step01").addClass("active");
        cash("#stepTo2").removeClass("active");
        cash("#step02").removeClass("active");
        cash("#step02info").removeClass("active");
        cash("#step03").removeClass("active");
        cash("#stepTo3").removeClass("active");
        cash("#step04").removeClass("active");
        cash("#stepTo4").removeClass("active");
        registerModal.formData = {
          isBuyer: false
        };
        verificationCode.value = "";
      },
      formValid(step: number) {
        let form = registerModal.formData;
        if (step == 1) {
          if (!form.account || !form.pwd) {
            alert("error", "驗證失敗", "請輸入必填欄位");
            return false;
          }
          if (form.pwd.length < 8 || form.pwd.length > 20) {
            alert("error", "驗證失敗", "密碼的長度至少為8");
            return false;
          }
          if (form.pwd != form.pwd2) {
            alert("error", "驗證失敗", "請輸入一致的密碼");
            return false;
          }
        } else if (step == 2) {
          if (!form.name || !form.mobile || !form.sex) {
            alert("error", "驗證失敗", "請輸入必填欄位");
            return false;
          }
        }
        return true;
      }
    });

    const alert = (type: string, subject: string, content: string) => {
      CloudFun.send(type, {
        subject: subject,
        content: content
      });
    };

    const register = () => {
      return model
        ?.dispatch("register/signup", registerModal.formData)
        .then(response => {
          //console.log(response);
          CloudFun.send(response.status ? "info" : "error", {
            subject: response.status ? "執行完成" : "執行失敗",
            content: response.status ? "註冊完成" : response.message
          });
          return response;
        })
        .catch(error => {
          console.log(error);
          Object.keys(error).forEach(el => {
            error[el].forEach((e: any) => {
              CloudFun.send("error", {
                subject: "註冊失敗",
                content: e
              });
            });
          });
        });
    };

    const doCountDown = async function() {
      if (!startCountDown.value) {
        startCountDown.value = true;
        countDown.value = countDownValue;
        timer.value = setInterval(() => {
          if (countDown.value > 0 && startCountDown.value) {
            countDown.value -= 1;
            // console.log("countDown", countDown.value);
            if (countDown.value == 0) startCountDown.value = false;
          }
        }, 1000);
      } else {
        clearInterval(timer.value);
        startCountDown.value = false;
        doCountDown();
      }
      if (!startCountDown.value) {
        countDown.value = countDownValue;
        clearInterval(timer.value);
      }
    };

    const gender = ref<any>([]);
    const modal = ref<any>();

    const schema = {
      step02: Yup.object().shape({
        account: Yup.string().required("必要欄位"),
        password: Yup.string()
          .required("必要欄位")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
            "密碼不符規則，請參考右側規則"
          ),
        passwordConfirm: Yup.string()
          .required("必要欄位")
          .oneOf([Yup.ref("password")], "密碼輸入不一致")
      }),
      step02InfoForMobile: Yup.object().shape({
        name: Yup.string().required("必要欄位"),
        mobile: Yup.string()
          .min(10, "請輸入正確手機號碼")
          .required("必要欄位"),
        sex: Yup.string().required("必要欄位")
      }),
      step02InfoForEmail: Yup.object().shape({
        name: Yup.string().required("必要欄位"),
        sex: Yup.string().required("必要欄位"),
        email: Yup.string()
          .email("E-mail格式錯誤")
          .required("必要欄位")
      }),
      step03: Yup.object().shape({
        verificationCode: Yup.string().required("必要欄位")
      })
    };

    const googleLogin = async () => {
      signInWithPopup(auth, googleProvider)
        .then(resp => {
          const user = {
            uid: resp?.user.uid,
            email: resp?.user.email,
            displayName: resp?.user.displayName,
            photoURL: resp?.user.photoURL
          };
          model?.dispatch("system/googleLogin", user).then(loginResult => {
            if (loginResult.status && loginResult.payload) {
              emit("socialLogin", loginResult);
              cash("#signIn-modal").modal("hide");
            }
          });
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "登入失敗",
            content: error
          });
        });
    };

    onMounted(async () => {
      registerModal.reset();
      var genderData = await model?.dispatch("option/find", ["Gender"]);
      if (genderData) gender.value = genderData.Gender;
    });

    return {
      registerModal,
      gender,
      submitText,
      verificationCode,
      modal,
      accountType,
      countDown,
      countDownValue,
      doCountDown,
      startCountDown,
      resendBody,
      googleLogin,
      isVisible,
      schema,
      check1,
      check2,
      register,
      verifySms,
      ssoCllback,
      closeModal
    };
  },
  methods: {
    toStep2(type: boolean) {
      this.registerModal.formData.isBuyer = type;
      cash("#stepTo1").removeClass("active");
      cash("#step01").removeClass("active");
      cash("#step02info").removeClass("active");
      cash("#stepTo2").addClass("active");
      cash("#step02").addClass("active");
    },
    toChange(type: boolean) {
      this.registerModal.formData.isBuyer = type;
      cash("#step03").removeClass("active");
      cash("#stepTo3").removeClass("active");
      cash("#step02info").removeClass("active");
      cash("#stepTo2").addClass("active");
      cash("#step02").addClass("active");
    },
    toStep1() {
      cash("#stepTo1").addClass("active");
      cash("#step01").addClass("active");
      cash("#stepTo2").removeClass("active");
      cash("#step02").removeClass("active");
    },
    toStep2info() {
      // const result = this.registerModal.formValid(1);
      // if (!result) return;
      //console.log(this.registerModal.formData.isBuyer)
      if (this.registerModal.formData.isBuyer == true) {
        cash("#step02").removeClass("active");
        cash("#step02info").addClass("active");
      } else {
        this.toStep3();
      }
    },
    socialToStep2Info() {
      cash("#step02").removeClass("active");
      cash("#step02info").addClass("active");
    },
    async toStep3() {
      // const result = this.registerModal.formValid(2);
      // if (!result) return;
      this.registerModal.formData.displayName = "null";
      if (this.accountType == "email") {
        this.registerModal.formData.email = this.registerModal.formData.account;
        if (this.registerModal.formData.isBuyer == false)
          this.registerModal.formData.mobile = "";
      } else if (this.accountType == "phone") {
        this.registerModal.formData.mobile = this.registerModal.formData.account;
        if (this.registerModal.formData.isBuyer == false)
          this.registerModal.formData.email = "";
      }
      if (this.registerModal.formData.isBuyer == false) {
        this.registerModal.formData.name = "";
        this.registerModal.formData.sex = "";
      }

      var response = await this.register();

      if (response.status) {
        this.resendBody.key = response.payload.sendKey;
        this.resendBody.type = response.payload.type;
        if (this.registerModal.formData.isBuyer == false) {
          cash("#step02").removeClass("active");
          cash("#step02info").addClass("active");
        }
        cash("#step02info").removeClass("active");
        cash("#stepTo2").removeClass("active");
        cash("#stepTo3").addClass("active");
        cash("#step03").addClass("active");
        await this.registerModal.getVerify();
      }
    },
    toStep4() {
      cash("#step03").removeClass("active");
      cash("#stepTo3").removeClass("active");
      cash("#stepTo4").addClass("active");
      cash("#step04").addClass("active");
    },
    checkChange(e: any) {},
    async facebookLogin() {
      const resp = await Facebook.login();
      if (resp) {
        const user = {
          userID: resp?.authResponse.userID,
          email: resp?.email,
          name: resp?.name,
          picture: resp?.picture
        };
        const loginResult = await this.$model.dispatch(
          "system/facebookLogin",
          user
        );
        if (loginResult.status && loginResult.payload) {
          this.$emit("socialLogin", loginResult);
          cash("#signIn-modal").modal("hide");
        }
      }
    }
  }
});
</script>

<style scoped>
.a-disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}
</style>
