
/* eslint-disable */
import axios from "axios";
import { ref, reactive, onMounted, provide, computed, watch, toRef } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";
import formatDate from "xe-utils/toDateString";
import * as Facebook from "@/components/facebook-login";
import { installGoogleAuth } from "@/components/google-login";
import { useGapi } from "vue-gapi";
import { email, required } from "@vuelidate/validators";
import CfInputBox from "@/cloudfun/components/CfInputBox.vue";
import CfSelectBox from "@/cloudfun/components/CfSelectBox.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import firebaseConfig from "@/views/firebaseConfig";
import { useRoute, useRouter } from "vue-router";
import {
  getAuth,
  signInWithPopup,
  signOut,
  GoogleAuthProvider
} from "firebase/auth";
import { boolean } from "yup/lib/locale";
export default defineComponent({
  components: { CfInputBox, VForm: Form, CfSelectBox },
  props: {
    ssoCallback: {
      type: Boolean,
      required: false
    }
  },
  setup(props, { emit }) {
    firebaseConfig;
    const model = CloudFun.current?.model;
    const router = useRouter();
    const route = useRoute();
    const submitText = ref<string>("傳送驗證碼");
    const verificationCode = ref<string>("");
    const countDownValue = 300;
    const countDown = ref(countDownValue);
    const startCountDown = ref(false);
    const resendBody = reactive({ key: "", type: "" });
    const isVisible = ref(false);
    const check1 = ref(false);
    const check2 = ref(false);
    const googleProvider = new GoogleAuthProvider();
    const auth = getAuth();
    const verifySms = ref(false);
    const ssoCllback = toRef(props, "ssoCallback");
    const closeModal = ref();

    const accountType = computed(() =>
      registerModal.formData.account?.indexOf("@") != -1 ? "email" : "phone"
    );

    const timer = ref(0);

    const registerModal = reactive({
      formData: <any>{
        isBuyer: false,
        debug_SkipVerification: true,
        account: ""
        // pwd: '',
        // pwd2: '',
        // accountType: '',
        // isBuyer: false,
        // name: ''
        // sex: ''
        // email: ''
        // mobile: ''
      },
      async getVerify() {
        // if (resendBody?.key?.length > 0) {
        //   await registerModal.getCode(resendBody.key, true);
        // } else {
        //   const code = await registerModal.getCode(resendBody.key, false);
        //   console.log(accountType.value)
        //   if(code) {
        //     submitText.value = "重新傳送驗證碼";
        //     doCountDown();
        //   }
        // }
        const code = await registerModal.getCode(resendBody.key, true);
        if (code) {
          submitText.value = "重新傳送驗證碼";
          // if (accountType.value == "phone") {
          //   //手機暫時直接通過
          //   verificationCode.value = code;
          //   await registerModal.sendVerify();
          // }
        }
      },
      async getCode(sendKey: string, countDown: boolean) {
        return await model
          ?.dispatch("system/send", {
            sendKey: sendKey
          })
          .then(response => {
            //console.log(response);
            if (response.status) {
              if (countDown) doCountDown();
              return response.payload.code;
            } else {
              CloudFun.send(response.status ? "info" : "error", {
                subject: response.status ? "發送成功" : "發送失敗",
                content: response.status ? "發送失敗" : response.message
              });
            }
          })
          .catch(error => {
            console.log(error.message);
            CloudFun.send("error", {
              subject: "驗證碼發送失敗",
              content: error.message
            });
          });
      },
      async sendVerify() {
        if (!verificationCode.value) {
          CloudFun.send("error", {
            subject: "驗證失敗",
            content: "請輸入驗證碼"
          });
          return;
        }

        await model
          ?.dispatch("system/verify", {
            code: verificationCode.value
          })
          .then(response => {
            console.log(response);
            if (response.status) {
              //toStep4
              cash("#step03").removeClass("active");
              cash("#stepTo3").removeClass("active");
              cash("#stepTo4").addClass("active");
              cash("#step04").addClass("active");

              // setTimeout(() => closeModal.value.click(), 2000);
            } else {
              CloudFun.send(response.status ? "info" : "error", {
                subject: response.status ? "註冊成功" : "註冊失敗",
                content: response.status ? "註冊成功" : response.message
              });
            }
          })
          .catch(error => {
            console.log(error.message);
            CloudFun.send("error", {
              subject: "驗證失敗",
              content: error.message
            });
          });
      },

      reset() {
        cash("#stepTo1").addClass("active");
        cash("#step01").addClass("active");
        cash("#stepTo2").removeClass("active");
        cash("#step02").removeClass("active");
        cash("#step02info").removeClass("active");
        cash("#step03").removeClass("active");
        cash("#stepTo3").removeClass("active");
        cash("#step04").removeClass("active");
        cash("#stepTo4").removeClass("active");
        registerModal.formData = {
          isBuyer: false
        };
        verificationCode.value = "";
      },
      formValid(step: number) {
        let form = registerModal.formData;
        if (step == 1) {
          if (!form.account || !form.pwd) {
            alert("error", "驗證失敗", "請輸入必填欄位");
            return false;
          }
          if (form.pwd.length < 8 || form.pwd.length > 20) {
            alert("error", "驗證失敗", "密碼的長度至少為8");
            return false;
          }
          if (form.pwd != form.pwd2) {
            alert("error", "驗證失敗", "請輸入一致的密碼");
            return false;
          }
        } else if (step == 2) {
          if (!form.name || !form.mobile || !form.sex) {
            alert("error", "驗證失敗", "請輸入必填欄位");
            return false;
          }
        }
        return true;
      }
    });

    const alert = (type: string, subject: string, content: string) => {
      CloudFun.send(type, {
        subject: subject,
        content: content
      });
    };

    const register = () => {
      return model
        ?.dispatch("register/signup", registerModal.formData)
        .then(response => {
          //console.log(response);
          CloudFun.send(response.status ? "info" : "error", {
            subject: response.status ? "執行完成" : "執行失敗",
            content: response.status ? "註冊完成" : response.message
          });
          return response;
        })
        .catch(error => {
          console.log(error);
          Object.keys(error).forEach(el => {
            error[el].forEach((e: any) => {
              CloudFun.send("error", {
                subject: "註冊失敗",
                content: e
              });
            });
          });
        });
    };

    const doCountDown = async function() {
      if (!startCountDown.value) {
        startCountDown.value = true;
        countDown.value = countDownValue;
        timer.value = setInterval(() => {
          if (countDown.value > 0 && startCountDown.value) {
            countDown.value -= 1;
            // console.log("countDown", countDown.value);
            if (countDown.value == 0) startCountDown.value = false;
          }
        }, 1000);
      } else {
        clearInterval(timer.value);
        startCountDown.value = false;
        doCountDown();
      }
      if (!startCountDown.value) {
        countDown.value = countDownValue;
        clearInterval(timer.value);
      }
    };

    const gender = ref<any>([]);
    const modal = ref<any>();

    const schema = {
      step02: Yup.object().shape({
        account: Yup.string().required("必要欄位"),
        password: Yup.string()
          .required("必要欄位")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
            "密碼不符規則，請參考右側規則"
          ),
        passwordConfirm: Yup.string()
          .required("必要欄位")
          .oneOf([Yup.ref("password")], "密碼輸入不一致")
      }),
      step02InfoForMobile: Yup.object().shape({
        name: Yup.string().required("必要欄位"),
        mobile: Yup.string()
          .min(10, "請輸入正確手機號碼")
          .required("必要欄位"),
        sex: Yup.string().required("必要欄位")
      }),
      step02InfoForEmail: Yup.object().shape({
        name: Yup.string().required("必要欄位"),
        sex: Yup.string().required("必要欄位"),
        email: Yup.string()
          .email("E-mail格式錯誤")
          .required("必要欄位")
      }),
      step03: Yup.object().shape({
        verificationCode: Yup.string().required("必要欄位")
      })
    };

    const googleLogin = async () => {
      signInWithPopup(auth, googleProvider)
        .then(resp => {
          const user = {
            uid: resp?.user.uid,
            email: resp?.user.email,
            displayName: resp?.user.displayName,
            photoURL: resp?.user.photoURL
          };
          model?.dispatch("system/googleLogin", user).then(loginResult => {
            if (loginResult.status && loginResult.payload) {
              emit("socialLogin", loginResult);
              cash("#signIn-modal").modal("hide");
            }
          });
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "登入失敗",
            content: error
          });
        });
    };

    onMounted(async () => {
      registerModal.reset();
      var genderData = await model?.dispatch("option/find", ["Gender"]);
      if (genderData) gender.value = genderData.Gender;
    });

    return {
      registerModal,
      gender,
      submitText,
      verificationCode,
      modal,
      accountType,
      countDown,
      countDownValue,
      doCountDown,
      startCountDown,
      resendBody,
      googleLogin,
      isVisible,
      schema,
      check1,
      check2,
      register,
      verifySms,
      ssoCllback,
      closeModal
    };
  },
  methods: {
    toStep2(type: boolean) {
      this.registerModal.formData.isBuyer = type;
      cash("#stepTo1").removeClass("active");
      cash("#step01").removeClass("active");
      cash("#step02info").removeClass("active");
      cash("#stepTo2").addClass("active");
      cash("#step02").addClass("active");
    },
    toChange(type: boolean) {
      this.registerModal.formData.isBuyer = type;
      cash("#step03").removeClass("active");
      cash("#stepTo3").removeClass("active");
      cash("#step02info").removeClass("active");
      cash("#stepTo2").addClass("active");
      cash("#step02").addClass("active");
    },
    toStep1() {
      cash("#stepTo1").addClass("active");
      cash("#step01").addClass("active");
      cash("#stepTo2").removeClass("active");
      cash("#step02").removeClass("active");
    },
    toStep2info() {
      // const result = this.registerModal.formValid(1);
      // if (!result) return;
      //console.log(this.registerModal.formData.isBuyer)
      if (this.registerModal.formData.isBuyer == true) {
        cash("#step02").removeClass("active");
        cash("#step02info").addClass("active");
      } else {
        this.toStep3();
      }
    },
    socialToStep2Info() {
      cash("#step02").removeClass("active");
      cash("#step02info").addClass("active");
    },
    async toStep3() {
      // const result = this.registerModal.formValid(2);
      // if (!result) return;
      this.registerModal.formData.displayName = "null";
      if (this.accountType == "email") {
        this.registerModal.formData.email = this.registerModal.formData.account;
        if (this.registerModal.formData.isBuyer == false)
          this.registerModal.formData.mobile = "";
      } else if (this.accountType == "phone") {
        this.registerModal.formData.mobile = this.registerModal.formData.account;
        if (this.registerModal.formData.isBuyer == false)
          this.registerModal.formData.email = "";
      }
      if (this.registerModal.formData.isBuyer == false) {
        this.registerModal.formData.name = "";
        this.registerModal.formData.sex = "";
      }

      var response = await this.register();

      if (response.status) {
        this.resendBody.key = response.payload.sendKey;
        this.resendBody.type = response.payload.type;
        if (this.registerModal.formData.isBuyer == false) {
          cash("#step02").removeClass("active");
          cash("#step02info").addClass("active");
        }
        cash("#step02info").removeClass("active");
        cash("#stepTo2").removeClass("active");
        cash("#stepTo3").addClass("active");
        cash("#step03").addClass("active");
        await this.registerModal.getVerify();
      }
    },
    toStep4() {
      cash("#step03").removeClass("active");
      cash("#stepTo3").removeClass("active");
      cash("#stepTo4").addClass("active");
      cash("#step04").addClass("active");
    },
    checkChange(e: any) {},
    async facebookLogin() {
      const resp = await Facebook.login();
      if (resp) {
        const user = {
          userID: resp?.authResponse.userID,
          email: resp?.email,
          name: resp?.name,
          picture: resp?.picture
        };
        const loginResult = await this.$model.dispatch(
          "system/facebookLogin",
          user
        );
        if (loginResult.status && loginResult.payload) {
          this.$emit("socialLogin", loginResult);
          cash("#signIn-modal").modal("hide");
        }
      }
    }
  }
});
