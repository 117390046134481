
/* eslint-disable */
import { defineComponent, onMounted, reactive, ref } from "vue";
import CloudFun from "@cloudfun/core";
// import DarkModeSwitcher from "@/components/dark-mode-switcher/Main.vue";
import { mapActions } from "vuex";
import Modal from "@/components/signIn-modal/Main.vue";
import ForgotPasswordModal from "@/components/forgotPassword-modal/Main.vue";
import axios from "axios";
import * as Facebook from "@/components/facebook-login";
import { Form } from "vee-validate";
import CfInputBox from "@/cloudfun/components/CfInputBox.vue";
import * as Yup from "yup";
import firebaseConfig from "@/views/firebaseConfig";
import {
  getAuth,
  signInWithPopup,
  signOut,
  GoogleAuthProvider
} from "firebase/auth";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  components: {
    // DarkModeSwitcher,
    CfInputBox,
    VForm: Form,
    Modal,
    ForgotPasswordModal
  },
  setup() {
    firebaseConfig;
    const verificationKey = ref("");
    const captchaUrl = ref("");
    const model = CloudFun.current?.model;
    const googleProvider = new GoogleAuthProvider();
    const auth = getAuth();
    const router = useRouter();
    const route = useRoute();
    const ssoForm = ref();
    const ssoData = reactive({ returnUrl: "", code: "", token: "" });
    const actionUrl = ref(
      `${process.env.VUE_APP_BACKEND_URL}/SingleSignOn/Index`
    );
    const registerModal = ref();
    //const actionUrl = ref("https://jkorpela.fi/cgi-bin/echo.cgi");

    const schema = Yup.object().shape({
      account: Yup.string().required("必要欄位"),
      password: Yup.string().required("必要欄位"),
      captcha: Yup.string().required("必要欄位")
    });

    const formData = reactive({
      account: "",
      password: "",
      captcha: ""
    });

    onMounted(() => {
      cash("body")
        .removeClass("app")
        .removeClass("error-page")
        .addClass("login");
      cash("body")[0].click();

      GetCaptcha();

      const upgrade = localStorage.getItem("upgrade");
      if (upgrade) {
        formData.account = upgrade;
        localStorage.removeItem("upgrade");
      }

      if (route.query.returnUrl && route.query.code) {
        localStorage.setItem("returnUrl", route.query.returnUrl as string);
        localStorage.setItem("code", route.query.code as string);

        ssoData.returnUrl = route.query.returnUrl as string;
        ssoData.code = route.query.code as string;
      }

      if (route.query.reg && route.query.reg === "1") {
        registerModal.value.click();
      }
    });

    const GetCaptcha = () => {
      model
        ?.dispatch("system/captcha")
        .then((response: any) => {
          if (response.status) {
            const data = response.payload;
            captchaUrl.value = data.imageBase64;
            verificationKey.value = data.verificationKey;
          } else {
            CloudFun.send("error", {
              subject: "驗證碼取得失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    };

    const googleLogin = () => {
      signInWithPopup(auth, googleProvider)
        .then(resp => {
          const user = {
            uid: resp?.user.uid,
            email: resp?.user.email,
            displayName: resp?.user.displayName,
            photoURL: resp?.user.photoURL
          };

          model?.dispatch("system/googleLogin", user).then(loginResult => {
            setLogin(loginResult);
          });
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "登入失敗",
            content: error
          });
        });
    };

    const setLogin = async (response: any) => {
      if (response.payload && response.status) {
        sessionStorage.setItem(
          "EmptyNext-user",
          JSON.stringify(response.payload)
        );
        const userData = await getAccount(response.payload.token);
        if (userData != null) {
          console.log("userData", response);
          sessionStorage.setItem("userData", JSON.stringify(userData));
          CloudFun.send("info", {
            subject: "登入成功",
            content: `使用者 ${userData.name}`
          });

          if (route.query.returnUrl) {
            if (route.query.code) ssoCallback(response.payload.token);
            else {
              router.push(route.query.returnUrl as string);
            }
          } else router.push("/");
        }
      } else {
        CloudFun.send("error", {
          subject: "登入失敗",
          content: response.message
        });
      }
    };

    const getAccount = (token: string) => {
      return axios
        .get(`${process.env.VUE_APP_BACKEND_URL}/api/v1/Account`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
        .then(response => response.data?.payload);
    };

    const ssoCallback = (token: string) => {
      ssoData.returnUrl = localStorage.getItem("returnUrl") as string;
      ssoData.code = localStorage.getItem("code") as string;

      if (ssoData.returnUrl && ssoData.code) {
        cash("#token").val(token);
        ssoForm.value.submit();
      }
    };

    // const doRedirect = (redirect: Boolean) => {
    //   if (redirect && route.query.returnUrl) {
    //     if (route.query.code) ssoCallback(response.payload.token);
    //     else {
    //       router.push(route.query.returnUrl as string);
    //     }
    //   } else router.push("/");
    // };

    return {
      schema,
      verificationKey,
      rememberMe: false,
      captchaUrl,
      GetCaptcha,
      formData,
      googleLogin,
      setLogin,
      getAccount,
      ssoCallback,
      actionUrl,
      ssoForm,
      ssoData,
      registerModal
    };
  },
  methods: {
    ...mapActions(["login"]),
    async formSubmit() {
      const response = await this.LOGIN();
      if (response) {
        await this.setLogin(response);
      }
    },
    reloadCaptcha() {
      this.GetCaptcha();
    },
    LOGIN() {
      return this.$model
        .dispatch("system/login", {
          account: this.formData.account,
          pwd: this.formData.password,
          verificationKey: this.verificationKey,
          verificationCode: this.formData.captcha
        })
        .then(response => {
          return response;
        })
        .catch(error => {
          console.log(error.message);
          CloudFun.send("error", {
            subject: "登入失敗",
            content: error.message
          });
        });
    },
    async facebookLogin() {
      const resp = await Facebook.login();
      if (resp) {
        const user = {
          userID: resp?.authResponse.userID,
          email: resp?.email,
          name: resp?.name,
          picture: resp?.picture
        };
        const loginResult = await this.$model.dispatch(
          "system/facebookLogin",
          user
        );
        if (loginResult.status && loginResult.payload) {
          this.setLogin(loginResult);
        }
      }
    }
  }
});
