<template>
  <!-- <DarkModeSwitcher /> -->
  <div class="container sm:px-10">
    <div class="block xl:grid grid-cols-2 gap-4">
      <!-- BEGIN: Login Info -->
      <div class="hidden xl:flex flex-col min-h-screen">
        <a href="" class="-intro-x flex items-center pt-5">
          <img
            alt="CISA"
            class="w-12"
            :src="require(`@/assets/images/CISA.svg`)"
          />
          <span class="text-white text-3xl ml-3">
            CISA
          </span>
        </a>
        <div class="my-auto">
          <img
            alt="CISA"
            class="-intro-x w-1/2 -mt-16"
            :src="require(`@/assets/images/illustration.svg`)"
          />
        </div>
      </div>
      <!-- END: Login Info -->
      <!-- BEGIN: Login Form -->
      <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-0">
        <div
          class="my-auto mx-auto xl:ml-20 bg-white xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
        >
          <h2
            class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
          >
            會員登入
          </h2>
          <div class=" text-left text-gray-600 mt-5">本站會員</div>
          <v-form
            :validation-schema="schema"
            @submit="formSubmit"
            :initial-values="formData"
          >
            <div class="intro-x mt-3">
              <CfInputBox
                name="account"
                placeholder="帳號(行動電話/Email)"
                type="text"
                v-model="formData.account"
                :showMessage="false"
              ></CfInputBox>
              <CfInputBox
                name="password"
                placeholder="密碼"
                type="password"
                v-model="formData.password"
                :showMessage="false"
              ></CfInputBox>
              <!-- <input
                ref="headline"
                type="text"
                class="intro-x login__input input input--lg border border-gray-300 block"
                placeholder="帳號(行動電話/Email)"
                v-model="account"
              /> -->
              <!-- <input
                type="password"
                class="intro-x login__input input input--lg border border-gray-300 block mt-4"
                placeholder="密碼"
                v-model="password"
              /> -->
            </div>
            <div
              class="intro-x flex flex-row text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
            >
              <!-- <input type="hidden" v-model="captchaToken" /> -->
              <div class="flex w-full">
                <div class="w-3/5">
                  <CfInputBox
                    name="captcha"
                    placeholder="驗證碼"
                    type="text"
                    v-model="formData.captcha"
                    :showMessage="false"
                  ></CfInputBox>
                </div>
                <div class="flex">
                  <img
                    class="pl-4 align-middle"
                    :src="captchaUrl"
                    @click="reloadCaptcha()"
                  />
                </div>
              </div>
              <!-- <input
                type="text"
                class="intro-x input input--lg border border-gray-300 block"
                placeholder="驗證碼"
                v-model="captcha"
              /> -->
            </div>
            <div
              class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
            >
              <div class="flex items-center mr-auto">
                <input
                  id="remember-me"
                  type="checkbox"
                  class="input border mr-2"
                  v-model="rememberMe"
                />
                <label class="cursor-pointer select-none" for="remember-me"
                  >記住我</label
                >
              </div>
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left mb-8">
              <button
                type="submit"
                class="button button--lg login__button text-white bg-theme-1 xl:mr-3 align-top mb-2 xl:mb-0"
              >
                登入
              </button>
            </div>
          </v-form>

          <div class=" sm:flex items-center mb-8 text-center">
            <a
              href="javascript:;"
              data-toggle="modal"
              data-target="#forgotPassword-modal"
              class="button button--lg w-24 text-theme-1 bg-transparent mb-3 sm:mb-0 align-top"
            >
              忘記密碼
            </a>
            <div
              class=" flex items-center justify-center sm:pl-5 sm:border-l sm:border-theme-5"
            >
              <div class=" text-gray-600">還不是會員嗎？</div>
              <a
                href="javascript:;"
                data-toggle="modal"
                ref="registerModal"
                data-target="#signIn-modal"
                class="button button--lg w-24 text-theme-1 bg-transparent align-top"
              >
                註冊會員
              </a>
              <!-- <a
                href="javascript:;"
                @click="ssoCallback"
                class="button button--lg w-24 text-theme-1 bg-transparent align-top"
              >
                Test
              </a> -->
            </div>
          </div>

          <div class=" text-left text-gray-600 mb-3">社群帳號登入</div>
          <div
            class=" xl:flex items-center intro-x text-center xl:text-left mb-8"
          >
            <!-- <a
              href="#"
              @click="facebookLogin"
              class="button button--lg w-full xl:w-32 text-white bg-theme-1 xl:mr-3 align-top mb-2 xl:mb-0 block"
            >
              FACEBOOK
            </a> -->
            <!-- <a
              href="#"
              class="button button--lg w-full xl:w-32 text-white bg-theme-1 xl:mr-3 align-top mb-2 xl:mb-0 block disabled"
            >
              LINE
            </a> -->
            <a
              href="#"
              @click="googleLogin"
              class="button button--lg w-full xl:w-32 text-white bg-theme-1 xl:mr-3 align-top mb-2 xl:mb-0 block"
            >
              GOOGLE
            </a>
          </div>

          <div class=" text-theme-7">Copyright © 2021.CISA</div>
        </div>
      </div>
      <!-- END: Login Form -->
    </div>
  </div>
  <!-- 註冊Modal -->
  <Modal @socialLogin="setLogin" @close="() => registerModal" />
  <!-- 忘記密碼Modal -->
  <ForgotPasswordModal />

  <div>
    <form ref="ssoForm" :action="actionUrl" method="post">
      <input type="hidden" name="returnUrl" :value="ssoData.returnUrl" />
      <input type="hidden" name="code" :value="ssoData.code" />
      <input type="hidden" name="token" id="token" />
    </form>
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import { defineComponent, onMounted, reactive, ref } from "vue";
import CloudFun from "@cloudfun/core";
// import DarkModeSwitcher from "@/components/dark-mode-switcher/Main.vue";
import { mapActions } from "vuex";
import Modal from "@/components/signIn-modal/Main.vue";
import ForgotPasswordModal from "@/components/forgotPassword-modal/Main.vue";
import axios from "axios";
import * as Facebook from "@/components/facebook-login";
import { Form } from "vee-validate";
import CfInputBox from "@/cloudfun/components/CfInputBox.vue";
import * as Yup from "yup";
import firebaseConfig from "@/views/firebaseConfig";
import {
  getAuth,
  signInWithPopup,
  signOut,
  GoogleAuthProvider
} from "firebase/auth";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  components: {
    // DarkModeSwitcher,
    CfInputBox,
    VForm: Form,
    Modal,
    ForgotPasswordModal
  },
  setup() {
    firebaseConfig;
    const verificationKey = ref("");
    const captchaUrl = ref("");
    const model = CloudFun.current?.model;
    const googleProvider = new GoogleAuthProvider();
    const auth = getAuth();
    const router = useRouter();
    const route = useRoute();
    const ssoForm = ref();
    const ssoData = reactive({ returnUrl: "", code: "", token: "" });
    const actionUrl = ref(
      `${process.env.VUE_APP_BACKEND_URL}/SingleSignOn/Index`
    );
    const registerModal = ref();
    //const actionUrl = ref("https://jkorpela.fi/cgi-bin/echo.cgi");

    const schema = Yup.object().shape({
      account: Yup.string().required("必要欄位"),
      password: Yup.string().required("必要欄位"),
      captcha: Yup.string().required("必要欄位")
    });

    const formData = reactive({
      account: "",
      password: "",
      captcha: ""
    });

    onMounted(() => {
      cash("body")
        .removeClass("app")
        .removeClass("error-page")
        .addClass("login");
      cash("body")[0].click();

      GetCaptcha();

      const upgrade = localStorage.getItem("upgrade");
      if (upgrade) {
        formData.account = upgrade;
        localStorage.removeItem("upgrade");
      }

      if (route.query.returnUrl && route.query.code) {
        localStorage.setItem("returnUrl", route.query.returnUrl as string);
        localStorage.setItem("code", route.query.code as string);

        ssoData.returnUrl = route.query.returnUrl as string;
        ssoData.code = route.query.code as string;
      }

      if (route.query.reg && route.query.reg === "1") {
        registerModal.value.click();
      }
    });

    const GetCaptcha = () => {
      model
        ?.dispatch("system/captcha")
        .then((response: any) => {
          if (response.status) {
            const data = response.payload;
            captchaUrl.value = data.imageBase64;
            verificationKey.value = data.verificationKey;
          } else {
            CloudFun.send("error", {
              subject: "驗證碼取得失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    };

    const googleLogin = () => {
      signInWithPopup(auth, googleProvider)
        .then(resp => {
          const user = {
            uid: resp?.user.uid,
            email: resp?.user.email,
            displayName: resp?.user.displayName,
            photoURL: resp?.user.photoURL
          };

          model?.dispatch("system/googleLogin", user).then(loginResult => {
            setLogin(loginResult);
          });
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "登入失敗",
            content: error
          });
        });
    };

    const setLogin = async (response: any) => {
      if (response.payload && response.status) {
        sessionStorage.setItem(
          "EmptyNext-user",
          JSON.stringify(response.payload)
        );
        const userData = await getAccount(response.payload.token);
        if (userData != null) {
          console.log("userData", response);
          sessionStorage.setItem("userData", JSON.stringify(userData));
          CloudFun.send("info", {
            subject: "登入成功",
            content: `使用者 ${userData.name}`
          });

          if (route.query.returnUrl) {
            if (route.query.code) ssoCallback(response.payload.token);
            else {
              router.push(route.query.returnUrl as string);
            }
          } else router.push("/");
        }
      } else {
        CloudFun.send("error", {
          subject: "登入失敗",
          content: response.message
        });
      }
    };

    const getAccount = (token: string) => {
      return axios
        .get(`${process.env.VUE_APP_BACKEND_URL}/api/v1/Account`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
        .then(response => response.data?.payload);
    };

    const ssoCallback = (token: string) => {
      ssoData.returnUrl = localStorage.getItem("returnUrl") as string;
      ssoData.code = localStorage.getItem("code") as string;

      if (ssoData.returnUrl && ssoData.code) {
        cash("#token").val(token);
        ssoForm.value.submit();
      }
    };

    // const doRedirect = (redirect: Boolean) => {
    //   if (redirect && route.query.returnUrl) {
    //     if (route.query.code) ssoCallback(response.payload.token);
    //     else {
    //       router.push(route.query.returnUrl as string);
    //     }
    //   } else router.push("/");
    // };

    return {
      schema,
      verificationKey,
      rememberMe: false,
      captchaUrl,
      GetCaptcha,
      formData,
      googleLogin,
      setLogin,
      getAccount,
      ssoCallback,
      actionUrl,
      ssoForm,
      ssoData,
      registerModal
    };
  },
  methods: {
    ...mapActions(["login"]),
    async formSubmit() {
      const response = await this.LOGIN();
      if (response) {
        await this.setLogin(response);
      }
    },
    reloadCaptcha() {
      this.GetCaptcha();
    },
    LOGIN() {
      return this.$model
        .dispatch("system/login", {
          account: this.formData.account,
          pwd: this.formData.password,
          verificationKey: this.verificationKey,
          verificationCode: this.formData.captcha
        })
        .then(response => {
          return response;
        })
        .catch(error => {
          console.log(error.message);
          CloudFun.send("error", {
            subject: "登入失敗",
            content: error.message
          });
        });
    },
    async facebookLogin() {
      const resp = await Facebook.login();
      if (resp) {
        const user = {
          userID: resp?.authResponse.userID,
          email: resp?.email,
          name: resp?.name,
          picture: resp?.picture
        };
        const loginResult = await this.$model.dispatch(
          "system/facebookLogin",
          user
        );
        if (loginResult.status && loginResult.payload) {
          this.setLogin(loginResult);
        }
      }
    }
  }
});
</script>
<style scope>
a.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}
</style>
