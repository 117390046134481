import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCr7AgcKNH1H2VjQDntaURXZdWvqNx9bT0",
  authDomain: "software-d8f99.firebaseapp.com",
  projectId: "software-d8f99"
  //   appId: '1:1020787758603:web:7c9987bc90241d3e25d90e',
  //   measurementId: 'G-2T9FS3GGQF',
};

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
