<template>
  <!-- 忘記密碼modal -->
  <div
    id="forgotPassword-modal"
    class="modal flex justify-center items-center pointer-events-none"
  >
    <div
      class="modal__content modal__content--xl max-h-screen overflow-y-scroll sm:overflow-y-auto relative w-screen p-6 max-w-screen-lg pointer-events-auto"
    >
      <a
        data-dismiss="modal"
        href="javascript:;"
        @click="reset"
        class="absolute right-0 top-0 mt-6 mr-6"
      >
        <xIcon class="w-8 h-8 text-gray-500" />
      </a>

      <div
        class="signin-stepper nav-tabs lg:flex items-center justify-between  mr-16 pb-5"
      >
        <a id="passwordSteper1" class="mb-2 active">
          <span class="">01</span>輸入帳號
        </a>
        <div class=" w-12 h-0.5 bg-gray-500 hidden lg:block"></div>
        <a id="passwordSteper2" class="mb-2 ">
          <span class="">02</span>信箱或簡訊驗證碼
        </a>
        <div class=" w-12 h-0.5 bg-gray-500 hidden lg:block"></div>
        <a id="passwordSteper3" class="mb-2 ">
          <span class="">03</span>重設密碼
        </a>
        <div class=" w-12 h-0.5 bg-gray-500 hidden lg:block"></div>
        <a id="passwordSteper4" class="mb-2 ">
          <span class="">04</span>完成設定！
        </a>
      </div>

      <div class="tab-content">
        <!-- 確認您的信箱／行動號碼 -->
        <div id="passwordStep1" class="tab-content__pane active">
          <v-form :validation-schema="step1ValidationSchema" @submit="submit">
            <div class="">
              <div class="font-bold text-2xl xl:text-3xl mt-3">
                確認您的信箱／行動號碼
              </div>
              <div class="py-8 px-0">
                <div class=" text-base">
                  請輸入您註冊時使用的信箱或行動號碼
                </div>
                <div class=" w-full lg:w-2/3 my-5">
                  <CfInputBox
                    label="帳號"
                    name="email"
                    placeholder="Email／行動電話"
                    type="text"
                    :isRequired="true"
                    v-model="formData.email"
                  ></CfInputBox>
                </div>
              </div>
            </div>
            <div class=" flex items-center justify-end pb-5">
              <button
                type="submit"
                class="button  button--lg w-full lg:w-32 text-white bg-theme-1 align-top mb-2 lg:mb-0"
              >
                下一步
              </button>
            </div>
          </v-form>
        </div>

        <!-- 進行驗證 -->
        <div id="passwordStep2" class="tab-content__pane">
          <v-form :validation-schema="step2ValidationSchema" @submit="postCode">
            <div class="">
              <div class="font-bold text-2xl xl:text-3xl mt-3">
                進行驗證
              </div>
              <div class="py-8 px-0">
                <div class=" w-full lg:w-2/3 text-base">
                  我們已經將驗證碼發送至您設定的信箱 {{ formData.email }} <br />
                  請在300秒內輸入您收到的驗證碼完成驗證,以避免驗證碼失效。
                </div>
                <div class=" sm:flex items-center my-5">
                  <div class=" flex items-center">
                    <CfInputBox
                      name="verificationCode"
                      placeholder="驗證碼"
                      type="text"
                      :showMessage="false"
                      v-model="verificationCode"
                      @update:modelValue="
                        val => {
                          verificationCode = val;
                        }
                      "
                    ></CfInputBox>
                    <button
                      type="submit"
                      class="button ml-2 button--lg w-full lg:w-32 text-white bg-theme-1 align-top mb-2 lg:mb-0"
                    >
                      送出
                    </button>
                  </div>
                  <div class="">
                    <a
                      href="#"
                      class=" button button--lg text-theme-1 bg-transparent mb-3 sm:mb-0 align-top"
                      v-if="startCountDown"
                    >
                      {{ countDown }} 秒
                    </a>

                    <a
                      href="#"
                      @click="getCode"
                      :class="{ 'a-disabled': startCountDown }"
                      class=" button button--lg text-theme-1 bg-transparent mb-3 sm:mb-0 align-top"
                      v-if="!startCountDown"
                    >
                      {{ submitText }}
                    </a>
                    <!-- 
                        <a href="#" class="button button--lg text-theme-1 bg-transparent mb-3 sm:mb-0 align-top">
                          傳送驗證碼 
                        </a>
                      -->
                  </div>
                </div>
              </div>
            </div>
          </v-form>
        </div>

        <!-- 重新設定密碼 -->
        <div id="passwordStep3" class="tab-content__pane">
          <v-form
            :validation-schema="step3ValidationSchema"
            @submit="changePwd"
          >
            <div class="">
              <div class="font-bold text-2xl xl:text-3xl mt-3">
                重新設定密碼
              </div>
              <div class="py-8 px-0">
                <div class=" text-base">
                  請輸入8~20字元(數字/英文字母大小寫/符號)
                </div>
                <div class=" my-5">
                  <!-- <div class="">
                    <div class=" w-full lg:w-2/3">
                      <CfInputBox
                        label="舊密碼"
                        name="password"
                        placeholder="舊密碼"
                        type="password"
                        v-model="password"
                        :showMessage="false"
                      ></CfInputBox>
                    </div>
                  </div> -->
                  <div class=" lg:flex items-end">
                    <div class=" w-full lg:w-2/3">
                      <CfInputBox
                        label="新密碼"
                        name="password"
                        placeholder="新密碼"
                        type="password"
                        v-model="pwdData.pwd"
                        :showMessage="true"
                      ></CfInputBox>
                    </div>
                    <!-- <div class="text-sm text-green-600 ml-2 mb-3 lg:mb-6 flex items-center">
                      <CheckCircleIcon />此密碼可使用
                    </div> -->
                  </div>
                  <div class=" lg:flex items-end">
                    <div class=" w-full lg:w-2/3">
                      <CfInputBox
                        label="再次輸入新密碼"
                        name="password2"
                        placeholder="再次輸入新密碼"
                        type="password"
                        v-model="pwdData.pwd2"
                        :showMessage="true"
                      ></CfInputBox>
                    </div>
                    <!-- <div class="text-sm text-green-600 ml-2 mb-3 lg:mb-6 flex items-center">
                      <CheckCircleIcon />此密碼可使用
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class=" flex items-center justify-end pb-5">
              <button
                type="submit"
                class="button  button--lg w-full lg:w-32 text-white bg-theme-1 align-top mb-2 lg:mb-0"
              >
                下一步
              </button>
            </div>
          </v-form>
        </div>

        <!-- 設定完成 -->
        <div id="passwordStep4" class="tab-content__pane">
          <div class="">
            <div class="font-bold text-2xl xl:text-3xl mt-3">
              設定完成
            </div>
            <div class="py-8 px-0">
              <div class=" text-base">
                您已成功重設密碼,請重新登入。
              </div>
            </div>
          </div>
          <div class="flex items-center pb-5">
            <a
              href="/"
              class="button button--lg w-full lg:w-36 text-white bg-theme-1 align-top mb-2 lg:mb-0"
            >
              完成並重新登入
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
/* eslint-disable */

import { ref, reactive, onMounted, provide } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";
import CfInputBox from "@/cloudfun/components/CfInputBox.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";

export default defineComponent({
  components: { CfInputBox, VForm: Form },
  setup() {
    const model = CloudFun.current?.model;
    const captchaUrl = ref("");
    const formData = reactive({
      email: "",
      captchaCode: ""
    });
    const pwdData = reactive({
      pwd: "",
      pwd2: ""
    });

    const startCountDown = ref(false);
    const submitText = ref<string>("傳送驗證碼");
    const countDownValue = 300;
    const countDown = ref(countDownValue);
    const sendKey = ref<string>("");
    const changeKey = ref<string>("");
    const verificationCode = ref<string>("");

    const getCaptcha = () => {
      model?.dispatch("system/captcha").then((response: any) => {
        console.log("response", response);
        if (response.status) {
          captchaUrl.value = response.payload.imageBase64;
        } else {
          CloudFun.send("error", {
            subject: "驗證碼取得失敗",
            content: response.message
          });
        }
      });
    };

    const step1ValidationSchema = Yup.object().shape({
      email: Yup.string().required("必要欄位")
    });
    const step2ValidationSchema = Yup.object().shape({
      verificationCode: Yup.string().required("必要欄位")
    });
    const step3ValidationSchema = Yup.object().shape({
      password: Yup.string()
        .min(8, "不得小於8位元")
        .max(20, "不得大於20位元")
        .required("必要欄位"),
      password2: Yup.string()
        .min(8, "不得小於8位元")
        .max(20, "不得大於20位元")
        .oneOf([Yup.ref("password")], "請輸入一致的密碼")
        .required("必要欄位")
    });

    onMounted(() => {
      getCaptcha();
    });

    const reset = () => {
      cash("#passwordSteper1").addClass("active");
      cash("#passwordStep1").addClass("active");
      cash("#passwordSteper2").removeClass("active");
      cash("#passwordStep2").removeClass("active");
      cash("#passwordSteper3").removeClass("active");
      cash("#passwordStep3").removeClass("active");
      cash("#passwordSteper4").removeClass("active");
      cash("#passwordStep4").removeClass("active");
      formData.email = "";
      submitText.value = "傳送驗證碼";
    };

    const doCountDown = async function() {
      startCountDown.value = true;
      countDown.value = countDownValue;
      const timer = setInterval(() => {
        if (countDown.value > 0 && startCountDown.value) {
          countDown.value -= 1;
          console.log("countDown", countDown.value);
          if (countDown.value == 0) startCountDown.value = false;
        }
      }, 1000);
      if (!startCountDown.value) {
        countDown.value = countDownValue;
        clearInterval(timer);
      }
    };

    return {
      captchaUrl,
      getCaptcha,
      formData,
      step1ValidationSchema,
      step2ValidationSchema,
      step3ValidationSchema,
      sendKey,
      reset,
      submitText,
      countDown,
      doCountDown,
      verificationCode,
      startCountDown,
      changeKey,
      pwdData
    };
  },
  methods: {
    toPasswordStep2() {
      cash("#passwordSteper1").removeClass("active");
      cash("#passwordStep1").removeClass("active");
      cash("#passwordSteper2").addClass("active");
      cash("#passwordStep2").addClass("active");
    },
    toPasswordStep3() {
      cash("#passwordSteper2").removeClass("active");
      cash("#passwordStep2").removeClass("active");
      cash("#passwordSteper3").addClass("active");
      cash("#passwordStep3").addClass("active");
    },
    toPasswordStep4() {
      cash("#passwordSteper3").removeClass("active");
      cash("#passwordStep3").removeClass("active");
      cash("#passwordSteper4").addClass("active");
      cash("#passwordStep4").addClass("active");
    },
    reloadCaptcha() {
      this.getCaptcha();
    },
    async submit(value: any) {
      const sendKey = await this.getSendKey(value);
      //console.log('sendKey', sendKey)
      if (sendKey) {
        this.sendKey = sendKey;
        this.toPasswordStep2();
        //const code = await this.getCode(sendKey);
        //console.log(code)
      }
    },
    async changePwd() {
      await this.postChange();
    },
    async postCode() {
      if (this.verificationCode) {
        const changeKey = await this.codeVerify();
        if (changeKey) {
          this.changeKey = changeKey;
          this.toPasswordStep3();
        }
      } else {
        CloudFun.send("error", {
          subject: "忘記密碼",
          content: "請輸入驗證碼"
        });
      }
    },
    async postChange() {
      if (this.pwdData.pwd != this.pwdData.pwd2) {
        CloudFun.send("error", {
          subject: "忘記密碼",
          content: "請輸入一致的密碼"
        });
        return;
      }
      try {
        return this.$model
          .dispatch("system/forgotPasswordChange", {
            changeKey: this.changeKey,
            pwd: this.pwdData.pwd
          })
          .then((response: any) => {
            //console.log("forgot response", response);
            if (response.status) {
              this.toPasswordStep4();
            } else {
              CloudFun.send("error", {
                subject: "忘記密碼",
                content: response.message ?? "系統忙碌中，請稍後再試"
              });
            }
          });
      } catch (error) {
        console.log("error", error);
        CloudFun.send("error", {
          subject: "忘記密碼",
          content: "系統忙碌中，請稍後再試"
        });
      }
    },
    codeVerify() {
      try {
        return this.$model
          .dispatch("system/forgotPasswordVerify", {
            code: this.verificationCode
          })
          .then((response: any) => {
            //console.log("forgot response", response);
            if (response.status) {
              return response.payload.changeKey;
            } else {
              CloudFun.send("error", {
                subject: "忘記密碼",
                content: response.message ?? "系統忙碌中，請稍後再試"
              });
            }
          });
      } catch (error) {
        console.log("error", error);
        CloudFun.send("error", {
          subject: "忘記密碼",
          content: "系統忙碌中，請稍後再試"
        });
      }
    },
    getSendKey(value: any) {
      try {
        return this.$model
          .dispatch("system/forgotPassword", { account: value.email })
          .then((response: any) => {
            //console.log("forgot response", response);
            if (response.status && !response.payload?.isUnVerified) {
              return response.payload.sendKey;
            } else {
              CloudFun.send("error", {
                subject: "忘記密碼",
                content: response.message ?? "系統忙碌中，請稍後再試"
              });
            }
          });
      } catch (error) {
        console.log("error", error);
        CloudFun.send("error", {
          subject: "忘記密碼",
          content: "系統忙碌中，請稍後再試"
        });
      }
    },
    getCode() {
      try {
        return this.$model
          .dispatch("system/forgotPasswordSend", { sendKey: this.sendKey })
          .then((response: any) => {
            //console.log("forgot response", response);
            if (response.status) {
              CloudFun.send("info", {
                subject: "忘記密碼",
                content: "成功發送驗證信"
              });
              this.submitText = "重新傳送驗證碼";
              this.doCountDown();
              return response.payload.code;
            } else {
              CloudFun.send("error", {
                subject: "忘記密碼",
                content: "系統忙碌中，請稍後再試"
              });
            }
          });
      } catch (error) {
        console.log("error", error);
        CloudFun.send("error", {
          subject: "忘記密碼",
          content: "系統忙碌中，請稍後再試"
        });
      }
    }
  }
});
</script>
