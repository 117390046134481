<template>
  <div class=" w-full mb-3">
    <label class="font-medium text-lg" :for="name"
      >{{ label
      }}<span class=" text-red-400" v-show="isRequired">＊</span></label
    >
    <div class="mt-2">
      <select
        :name="name"
        v-model="value"
        class="intro-x input input--lg border border-gray-300 w-full"
        :class="{
          'border-red-500 bg-red-50': !!errorMessage,
          success: meta.valid
        }"
        :placeholder="placeholder"
        @change="handleChange"
        @blur="handleBlur"
        :disabled="disabled"
      >
        <option v-for="item in items" :key="item.value" :value="item.value">{{
          item.text
        }}</option>
      </select>
      <template v-if="showMessage">
        <div class="text-xs text-red-400" v-show="errorMessage">
          {{ errorMessage }}
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, toRef } from "vue";
import { useField } from "vee-validate";
import { boolean } from "yup/lib/locale";
export default defineComponent({
  props: {
    label: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      required: true
    },
    modelValue: {
      type: [String, Number] as PropType<any>,
      required: true
    },
    placeholder: {
      type: String,
      default: ""
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    showMessage: {
      type: Boolean,
      default: true
    },
    items: {
      type: [Array] as PropType<any>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const name = toRef(props, "name");
    const value = computed<any>({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit("update:modelValue", value);
      }
    });

    const { errorMessage, handleBlur, handleChange, meta } = useField(
      name,
      undefined,
      { initialValue: props.modelValue }
    );

    return {
      errorMessage,
      handleBlur,
      handleChange,
      meta,
      value
    };
  }
});
</script>

<style scoped></style>
